import React, {useEffect, useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    FormControlLabel,
    Switch,
    Box, FormControl, TextField, Grid,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {SimpleCleanUpNodeModel} from "../SimpleCleanUpNodeModel";

interface SimpleCleanUpNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: SimpleCleanUpNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const SimpleCleanUpNodeSettings: React.FC<SimpleCleanUpNodeSettingsProps> = (
    props: SimpleCleanUpNodeSettingsProps
) => {
    const dialogContentRef = React.useRef<HTMLDivElement>(null);

    const [removeLeadingTrailing, setRemoveLeadingTrailing] =
        useState<boolean>(false);
    const [replaceBackslash, setReplaceBackslash] = useState<boolean>(false);
    const [removeQuotes, setRemoveQuotes] = useState<boolean>(false);

    const onApply = () => {
        props.nodeModel.setRemoveLeadingTrailing(removeLeadingTrailing);
        props.nodeModel.setReplaceBackslash(replaceBackslash);
        props.nodeModel.setRemoveQuotes(removeQuotes);
        props.nodeModel.setComment(props.comment);

        const summary = [
            removeLeadingTrailing ? "Trim Spaces" : null,
            replaceBackslash ? "Backslash->Slash" : null,
            removeQuotes ? "Remove Quotes" : null,
        ]
            .filter(Boolean)
            .join(", ");
        props.setSettingsSummary(summary || "No cleanup operations");

        onClose();
    };

    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    useEffect(() => {
        props.setComment(props.nodeModel.comment);
        // eslint-disable-next-line
    }, [props.nodeModel.comment]);

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline={"Simple Clean-Up"} closeDialog={onClose}/>
            <DialogContent ref={dialogContentRef}>
                <Box display="flex" flexDirection="column" gap={2}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={removeLeadingTrailing}
                                onChange={(e) => setRemoveLeadingTrailing(e.target.checked)}
                            />
                        }
                        label="Leerzeichen am Anfang und Ende entfernen"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={replaceBackslash}
                                onChange={(e) => setReplaceBackslash(e.target.checked)}
                            />
                        }
                        label='Backslash "\" durch "/" ersetzen'
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={removeQuotes}
                                onChange={(e) => setRemoveQuotes(e.target.checked)}
                            />
                        }
                        label="Anführungszeichen/ Hochkommas entfernen"
                    />
                </Box>
                <Grid item xs={12} sx={{marginTop: "20px"}}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Node Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SimpleCleanUpNodeSettings;
