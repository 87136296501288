import {PortModelAlignment} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../../port/SparkyELTPortModel';
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class AppendNodeModel extends SparkyBasicNodeModel {

    inputColumnsTop: string[];
    inputColumnsBottom: string[];
    
    constructor() {
        super('Node_APPEND');
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));

        this.inputColumnsTop = []
        this.inputColumnsBottom = []
    }

    calculateOutputCols(): string[] {
        const tmp = this.calculateOutputCols_full()
        this.setOutputColumns(tmp.withoutPrefix);
        return tmp.withoutPrefix
    }

    calculateOutputCols_full(): { withPrefix: string[]; withoutPrefix: string[] } {

        const leftCols = this.getInputColumnsTop()
        const rightCols = this.getInputColumnsBottom()
        // 1. Start with the left DataFrame's columns.
        const unionCols: string[] = [...leftCols];

        // 2. Append columns from the right DataFrame that are not in the left.
        for (const col of rightCols) {
            if (!unionCols.includes(col)) {
                unionCols.push(col);
            }
        }

        // 3. Build the prefixed version of unionCols.
        const withPrefix = unionCols.map((col) => {
            const inLeft = leftCols.includes(col);
            const inRight = rightCols.includes(col);
            if (inLeft && inRight) {
                // Column appears in both arrays.
                return "m_" + col;
            } else if (inLeft) {
                // Column appears only in left.
                return "T_" + col;
            } else if (inRight) {
                // Column appears only in right.
                return "B_" + col;
            }
            return col; // Should not reach here.
        });

        return { withoutPrefix: unionCols, withPrefix };
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment || "";
        this.settingsSummary = this.calcSettingsSummary()
    }

    getInputColumnsTop(): string[] {
        return this.inputColumnsTop
    }

    setInputColumnsTop(cols: string[]): void {
        this.inputColumnsTop = cols
    }

    getInputColumnsBottom(): string[] {
        return this.inputColumnsBottom
    }

    setInputColumnsBottom(cols: string[]): void {
        this.inputColumnsBottom = cols
    }

    getOutputColumnsTop(): string[] {
        this.calculateOutputCols()
        return this.getOutputColumns()
    }

    getOutputColumnsBottom(): string[] {
        this.calculateOutputCols()
        return this.getOutputColumns()
    }

    calcSettingsSummary() {
        return "Append settings done";
    }

}