import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    ListItemText,
    List,
    IconButton,
    FormControl,
    TextField,
    Grid,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {Box, styled} from "@mui/system";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {BiArrowToRight} from "react-icons/bi";
import {RemoveColNodeModel} from "../RemoveColNodeModel";
import {propagateColsToChildren} from "../../../../../pages/home/Demo";

interface RemoveColsNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: RemoveColNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
});

const LeftBox = styled(Box)({
    width: "100px",
    height: "40px",
    backgroundColor: "lightblue",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "8px",
});

const RightBox = styled(Box)(({gray}: { gray: boolean }) => ({
    width: "150px",
    height: "40px",
    backgroundColor: gray ? "gray" : "lightgreen",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "8px",
    overflow: "hidden",
}));

const RemoveColsNodeSettings: React.FC<RemoveColsNodeSettingsProps> = (
    props: RemoveColsNodeSettingsProps
) => {
    const [removedColumns, setRemovedColumns] = useState<string[]>([]);
    const [renameMap, setRenameMap] = useState<Record<string, string>>({});
    
    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {
        
        // --        
        props.nodeModel.setRemovedColumns(removedColumns);
        props.nodeModel.setRenameMap(renameMap);
        // -- 
        
        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setComment(props.comment);
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary());
        props.setSettingsSummary(props.nodeModel.getSettingsSummary())

        propagateColsToChildren(props.nodeModel)
        
        onClose();
    };
    
    const handleArrowClick = (colName: string) => {
        if (removedColumns.includes(colName)) {
            const updatedRemovedColumns = removedColumns.filter(colN => colN !== colName);
            setRemovedColumns(updatedRemovedColumns)
        } else {
            setRemovedColumns([...removedColumns, colName]);
        }
    };

    const handleRenameChange = (origColName: string, newValue: string) => {
        setRenameMap(prevRenameMap => ({
            ...prevRenameMap,
            [origColName]: newValue,
        }));
    };

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(() => {
        setRemovedColumns(props.nodeModel.removedColumns)
        // eslint-disable-next-line
    }, [props.nodeModel.removedColumns])
    React.useEffect(() => {
        setRenameMap(props.nodeModel.renameMap)
        // eslint-disable-next-line
    }, [props.nodeModel.renameMap])

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline="Filter & Rename Columns" closeDialog={onClose}/>
            <DialogContent>
                <List>
                    {props.nodeModel.inputColumns.map((colName, index) => (
                        <StyledBox key={index}>
                            {/* Linke Box (Original-Name) */}
                            <LeftBox>
                                <ListItemText primary={colName}/>
                            </LeftBox>

                            {/* Pfeil / IconButton */}
                            <IconButton onClick={() => handleArrowClick(colName)}>
                                {removedColumns.includes(colName) ? (
                                    <BiArrowToRight/>
                                ) : (
                                    <ArrowForwardIcon
                                        style={{
                                            textDecoration: removedColumns.includes(colName)
                                                ? "line-through"
                                                : "none",
                                        }}
                                    />
                                )}
                            </IconButton>

                            {/* Rechte Box (neuer Name) */}
                            {/* Falls grau -> disabled */}
                            <RightBox gray={removedColumns.includes(colName)}>
                                <TextField
                                    size="small"
                                    disabled={removedColumns.includes(colName)}
                                    value={renameMap[colName] || colName}
                                    onChange={(e) => handleRenameChange(colName, e.target.value)}
                                    style={{flex: 1}}
                                    inputProps={{style: {textOverflow: "ellipsis", fontWeight: (renameMap[colName] ? "bold" : "normal")}}}
                                />
                            </RightBox>
                        </StyledBox>
                    ))}
                </List>

                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Node Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveColsNodeSettings;
