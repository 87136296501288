import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class GroupByNodeModel extends SparkyBasicNodeModel {
    
    groupedByColumns: string[];
    operationColumn: string;
    groupOperation: string;
    newColName: string;
    concatDelimiter: string;
    leftJoinResultsToOriginalData: boolean;  // if disabled this will return unique rows    

    constructor() {
        super("Node_GROUP_BY");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));
        this.groupedByColumns = [];
        this.operationColumn = "";
        this.groupOperation = "";
        this.newColName = "";
        this.concatDelimiter = "";
        this.leftJoinResultsToOriginalData = false;
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        const calcOutputCols = this.getInputColumns()
        calcOutputCols.push(this.getNewColName())
        this.setOutputColumns(calcOutputCols)
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            groupedByColumns: this.groupedByColumns,
            operationColumn: this.operationColumn,
            groupOperation: this.groupOperation,
            newColName: this.newColName,
            comment: this.comment,
            concatDelimiter: this.concatDelimiter,
            leftJoinResultsToOriginalData: this.leftJoinResultsToOriginalData,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.groupedByColumns = event.data.groupedByColumns || [];
        this.operationColumn = event.data.operationColumn || "";
        this.groupOperation = event.data.groupOperation || "";
        this.newColName = event.data.newColName || "";
        this.comment = event.data.comment || "";
        this.concatDelimiter = event.data.delimiter || "";
        this.settingsSummary = this.calcSettingsSummary()
        this.leftJoinResultsToOriginalData = event.data.leftJoinResultsToOriginalData || false;
    }

    setGroupedByColumns(cols: string[]): void {
        this.groupedByColumns = cols;
    }

    getGroupedByColumns(): string[] {
        return this.groupedByColumns;
    }

    setOperationColumn(column: string): void {
        this.operationColumn = column;
    }

    setGroupOperation(value: string): void {
        this.groupOperation = value;
    }

    setNewColName(name: string): void {
        this.newColName = name;
    }

    getNewColName(): string {
        return this.newColName;
    }

    setConcatDelimiter(delimiter: string): void {
        this.concatDelimiter = delimiter;
    }

    getConcatDelimiter(): string {
        return this.concatDelimiter;
    }
    
    setLeftJoinResultsToOriginalData(leftJoinResultsToOriginalData: boolean): void {
        this.leftJoinResultsToOriginalData = leftJoinResultsToOriginalData;
    }
    
    getLeftJoinResultsToOriginalData(): boolean {
        return this.leftJoinResultsToOriginalData;
    }

    calcSettingsSummary() {
        const summary = `
            ${this.groupedByColumns}, 
            ${this.groupOperation}(${this.operationColumn}) 
            AS ${this.newColName}${this.concatDelimiter.length > 0 ? ", del: " + this.concatDelimiter : ""}`;

        return summary;
    }
}
