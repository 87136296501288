import SimpleCleanUpNodeWidget from "./SimpleCleanUpNodeWidget";
import { SimpleCleanUpNodeModel } from "./SimpleCleanUpNodeModel";
import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { GenerateModelEvent } from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import { GenerateWidgetEvent } from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class SimpleCleanUpNodeFactory extends AbstractReactFactory<
  SimpleCleanUpNodeModel,
  DiagramEngine
> {
  constructor() {
    super("Node_SIMPLE_CLEAN_UP");
  }

  generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
    return (
      <SimpleCleanUpNodeWidget
        engine={this.engine}
        width={303}
        height={179}
        node={event.model}
      />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new SimpleCleanUpNodeModel();
  }
}
