import {Box, Typography} from "@mui/material";
import * as React from "react";
// import {useTranslation} from 'react-i18next';
import SpeedDial from "@mui/material/SpeedDial";
import {CiFilter, CiPlay1} from "react-icons/ci";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {NodeTypeEnum} from "./NodeTypeEnum";
import InputIcon from "@mui/icons-material/Input";
import {HiOutlineUserGroup} from "react-icons/hi";
import {RiDeleteColumn} from "react-icons/ri";
import {TbReorder, TbWash} from "react-icons/tb";
import {BsSortDown} from "react-icons/bs";
import {MdOutlineCalculate, MdOutlineFolderSpecial} from "react-icons/md";
import {MdFindReplace, MdJoinInner, MdOutlineDelete} from "react-icons/md";
import {CgArrowsMergeAltV, CgRename} from "react-icons/cg";
import OutputIcon from "@mui/icons-material/Output";
import {AiOutlineDeleteColumn} from "react-icons/ai";
import {LuSettings2} from "react-icons/lu";
import {FaRegBell} from "react-icons/fa";
import {IoEyeOutline} from "react-icons/io5";
import { LiaBroomSolid } from "react-icons/lia";

export interface IDefaultNodeDecorationProps {
    nodeType: NodeTypeEnum;
    comment: string;
    textOnNode?: string;
    numLinesOut?: number;
    numLinesOut2?: number;
    onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const actionsRemove = [
    {icon: <LuSettings2/>, name: "Edit Node Settings"},
    {icon: <FaRegBell/>, name: "Enable Notification"},
    {icon: <IoEyeOutline/>, name: "Preview Node Results"},
    {icon: <CiPlay1/>, name: "Run up to here"},
    {icon: <TbWash/>, name: "Re-load Metadata"},
    {icon: <AiOutlineDeleteColumn/>, name: "Remove All Connections"},
    {icon: <MdOutlineDelete/>, name: "Delete Node"},
];

const getIconFromNodeType = (nodeType: NodeTypeEnum): JSX.Element => {
    if (nodeType === NodeTypeEnum.NODE_READ) {
        return <InputIcon style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_GROUP_BY) {
        return <HiOutlineUserGroup style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_CALCULATE) {  // NODE_FILL_NEW_COL
        return <MdOutlineCalculate style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_REMOVE_COL) {
        return <RiDeleteColumn style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_FILTER) {
        return <CiFilter style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_APPEND) {
        return <CgArrowsMergeAltV style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_MERGE) {
        return <MdJoinInner style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_SORT) {
        return <BsSortDown style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_MAKE_UNIQUE) {
        return <MdOutlineFolderSpecial style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_RENAME_COL) {
        return <CgRename style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_REORDER_COL) {
        return <TbReorder style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_REPLACE_VALUE) {
        return <MdFindReplace style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_SIMPLE_CLEAN_UP) {
        return <LiaBroomSolid style={{fontSize: 35}}/>;
    } else if (nodeType === NodeTypeEnum.NODE_WRITE) {
        return <OutputIcon style={{fontSize: 35}}/>;
    } else {
        return <OutputIcon style={{fontSize: 35}}/>;
    }
};

const getFirstLineOfNodeLabelTextByNodeType = (
    nodeType: NodeTypeEnum
): string => {
    if (nodeType === NodeTypeEnum.NODE_READ) {
        return "Read";
    } else if (nodeType === NodeTypeEnum.NODE_GROUP_BY) {
        return "Group by";
    } else if (nodeType === NodeTypeEnum.NODE_CALCULATE) { // NODE_FILL_NEW_COL
        return "Calculate new Col";
    } else if (nodeType === NodeTypeEnum.NODE_REMOVE_COL) {
        return "Remove Col";
    } else if (nodeType === NodeTypeEnum.NODE_FILTER) {
        return "Filter";
    } else if (nodeType === NodeTypeEnum.NODE_APPEND) {
        return "Append";
    } else if (nodeType === NodeTypeEnum.NODE_MERGE) {
        return "Merge";
    } else if (nodeType === NodeTypeEnum.NODE_SORT) {
        return "Sort";
    } else if (nodeType === NodeTypeEnum.NODE_MAKE_UNIQUE) {
        return "Make Unique";
    } else if (nodeType === NodeTypeEnum.NODE_RENAME_COL) {
        return "Rename Cols";
    } else if (nodeType === NodeTypeEnum.NODE_REORDER_COL) {
        return "Re-Order Cols";
    } else if (nodeType === NodeTypeEnum.NODE_REPLACE_VALUE) {
        return "Replace Values";
    } else if (nodeType === NodeTypeEnum.NODE_SIMPLE_CLEAN_UP) {
        return "Clean";
    } else if (nodeType === NodeTypeEnum.NODE_WRITE) {
        return "Write";
    } else {
        return "";
    }
};

export const getPrimaryColorByNodeType = (nodeType: NodeTypeEnum): string => {
    if (nodeType === NodeTypeEnum.NODE_READ) {
        return "#93BF79";
    } else if (nodeType === NodeTypeEnum.NODE_GROUP_BY) {
        return "#D3CC84";
    } else if (nodeType === NodeTypeEnum.NODE_CALCULATE) {  // NODE_FILL_NEW_COL
        return "#D3CC84";
    } else if (nodeType === NodeTypeEnum.NODE_MAKE_UNIQUE) {
        return "#D3CC84";
    } else if (nodeType === NodeTypeEnum.NODE_REMOVE_COL) {
        return "#DBB78B";
    } else if (nodeType === NodeTypeEnum.NODE_FILTER) {
        return "#DBB78B";
    } else if (nodeType === NodeTypeEnum.NODE_APPEND) {
        return "#E3A293";
    } else if (nodeType === NodeTypeEnum.NODE_MERGE) {
        return "#E3A293";
    } else if (nodeType === NodeTypeEnum.NODE_SORT) {
        return "#DBC8FC";
    } else if (nodeType === NodeTypeEnum.NODE_RENAME_COL) {
        return "#DBC8FC";
    } else if (nodeType === NodeTypeEnum.NODE_REORDER_COL) {
        return "#DBC8FC";
    } else if (nodeType === NodeTypeEnum.NODE_REPLACE_VALUE) {
        return "#DBC8FC";
    } else if (nodeType === NodeTypeEnum.NODE_SIMPLE_CLEAN_UP) {
        return "#DBC8FC";
    } else if (nodeType === NodeTypeEnum.NODE_WRITE) {
        return "#E3EDFF";
    } else {
        return "#E3EDFF";
    }
};

const DefaultNodeDecoration: React.FunctionComponent<
    IDefaultNodeDecorationProps
> = (props: IDefaultNodeDecorationProps) => {
    // const { t } = useTranslation();

    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const calculatePosition = (index: number, total: number) => {
        const angle = (index / total) * 2 * Math.PI; // angle in radians
        const radius = 60; // radius of circle
        const x = Math.cos(angle) * radius;
        const y = Math.sin(angle) * radius;
        return {left: x, top: 15 - y};
    };

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDoubleClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        event.stopPropagation();
        if (props.onDoubleClick) {
            props.onDoubleClick(event);
        }
    };

    return (
        <>
            <Box
                sx={
                    open
                        ? {
                            position: "absolute",
                            left: -73,
                            top: -55,
                            borderRadius: "50%",
                            background: "radial-gradient(circle, white, transparent)",
                        }
                        : {
                            position: "absolute",
                            left: -73,
                            top: -55,
                        }
                }
                onDoubleClick={handleDoubleClick}
            >
                <div onDoubleClick={handleDoubleClick}>
                    <SpeedDial
                        ariaLabel="SpeedDial_remove"
                        FabProps={{
                            sx: {
                                bgcolor: "#FFFFFF",
                                "&:hover": {
                                    bgcolor: getPrimaryColorByNodeType(props.nodeType),
                                },
                            },
                        }}
                        sx={{position: "relative", margin: "100px"}}
                        icon={getIconFromNodeType(props.nodeType)}
                        onClick={handleClick}
                        open={open}
                    >
                        {actionsRemove.map((action, index: number) => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                sx={{
                                    position: "absolute",
                                    fontSize: 30,
                                    bgcolor: "white",
                                    color: getPrimaryColorByNodeType(props.nodeType),
                                    ...calculatePosition(index, actionsRemove.length),
                                }}
                                onClick={handleClose} // Close dial on action click
                            />
                        ))}
                    </SpeedDial>
                </div>
            </Box>
          
            {/* Text on node */}
            {props.textOnNode && (
                <Typography
                    align="center"
                    textAlign="center"
                    sx={{
                        position: "absolute",
                        top: (props.nodeType === NodeTypeEnum.NODE_GROUP_BY && props.textOnNode.length > 15 ? 25 : 65),
                        left: 110,
                        width: 160,
                        backgroundColor: "rgba(255,255,255,0.5)",
                        borderRadius: "15px",
                    }}
                >
                    {getFirstLineOfNodeLabelTextByNodeType(props.nodeType)}
                    <br/>
                    {props.textOnNode}
                </Typography>
            )}

            {/* Comment below node */}
            {props.comment && (
                <Typography variant="subtitle1" align="center" textAlign="center">
                    {props.comment}
                </Typography>
            )}

            {/* Number of lines out */}
            {props.numLinesOut && (
                <Typography
                    variant="subtitle2"
                    align="center"
                    textAlign="center"
                    sx={{
                        position: "absolute", top: 20, right: -60, width: 60,
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                        borderRadius: "8px", 
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        color: "white", // Text color for better contrast
                    }}
                >
                    {props.numLinesOut.toLocaleString("en-US")}
                </Typography>
            )}

            {/* Number of lines out II */}
            {props.numLinesOut2 && (
                <Typography
                    variant="subtitle2"
                    align="center"
                    textAlign="center"
                    sx={{position: "absolute", top: 140, right: -60, width: 60}}
                >
                    {props.numLinesOut2}
                </Typography>
            )}
        </>
    );
};

export default DefaultNodeDecoration;
