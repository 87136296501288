import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    FormControl,
    TextField,
    MenuItem, IconButton,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {MakeUniqueNodeModel} from "../MakeUniqueNodeModel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {propagateColsToChildren} from "../../../../../pages/home/Demo";

interface MakeUniqueNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: MakeUniqueNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const MakeUniqueNodeSettings: React.FC<MakeUniqueNodeSettingsProps> = (props: MakeUniqueNodeSettingsProps) => {

    const [uniqueCols, setUniqueCols] = useState<string[]>([""]);
    const [inputCols, setInputCols] = useState<string[]>([""]);

    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {
        
        // --
        props.nodeModel.setUniqueColumns(uniqueCols)
        // --

        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setComment(props.comment);
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary());
        props.setSettingsSummary(props.nodeModel.getSettingsSummary())

        propagateColsToChildren(props.nodeModel)

        onClose();
    };

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(() => {
        setUniqueCols(props.nodeModel.uniqueColumns)
        // eslint-disable-next-line
    }, [props.nodeModel.uniqueColumns])
    React.useEffect(() => {
        setInputCols(props.nodeModel.inputColumns)
        // eslint-disable-next-line
    }, [props.nodeModel.inputColumns])

    const handleAddUniqueCol = () => {
        setUniqueCols([...uniqueCols, ""]);
    };

    const handleRemoveUniqueCol = (index: number) => {
        const updatedSortCols = uniqueCols.filter((_, i) => i !== index);
        setUniqueCols(updatedSortCols);
    };

    const handleUniqueColChange = (value: string, index: number) => {
        const updatedSortCols = [...uniqueCols];
        updatedSortCols[index] = value;
        setUniqueCols(updatedSortCols);
    };


    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline={"Make Unique Node Settings"} closeDialog={onClose}/>
            <DialogContent>
                <Grid container sx={{marginTop: '10px'}} spacing={1}>
                    {uniqueCols.map((uniqueColname, index) => (
                        <>
                            <Grid item xs={11.5} key={index + "_colname"}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label={`Make Unique Column ${index + 1}`}
                                        value={uniqueColname}
                                        onChange={(e) => handleUniqueColChange(e.target.value, index)}
                                    >
                                        {inputCols.map((col, idx) => (
                                            <MenuItem key={index + "_" + idx} value={col}>
                                                {col}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={0.5} key={index + "_rem"}
                                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconButton onClick={() => handleRemoveUniqueCol(index)} color="error">
                                    <RemoveCircleOutlineIcon/>
                                </IconButton>
                            </Grid>
                        </>
                    ))}
                </Grid>
                <Grid item xs={12} sx={{marginTop: '10px', marginBottom: '25px'}}>
                    <IconButton onClick={handleAddUniqueCol} color="success">
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '35px'}}>
                    <hr/>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '15px'}}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Node Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MakeUniqueNodeSettings;
