import {PortModelAlignment} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export type ValueType =
    | {
    type: "string";
    value: string
}
    | {
    type: "column";
    column: string
};

export type BasicCalculation = {
    column: string;
    operation: string;
    value: ValueType;
    outputColumn: string;
}

export type DateCalculation = {
    column: string;
    dateFormat: string;
    operator: string;
    value: number;
    unit: string;
    outputColumn: string;
};

export type DaysUntilCalculation = {
    column: string;
    targetDate: string;
    dateFormat: string;
    outputColumn: string;
};

export class CalculateNodeModel extends SparkyBasicNodeModel {

    basicCalculations: BasicCalculation[];
    dateCalculations: DateCalculation[];
    daysUntilCalculations: DaysUntilCalculation[];

    constructor() {
        super("Node_CALCULATE");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));

        this.basicCalculations = [];
        this.dateCalculations = [];
        this.daysUntilCalculations = [];
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        const allCalculations = [
            ...this.basicCalculations,
            ...this.dateCalculations,
            ...this.daysUntilCalculations,
        ];
        const allNewCols = allCalculations.map((c) => c.outputColumn)
        this.setOutputColumns(this.getInputColumns().concat(allNewCols))
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,

            basicCalculations: this.basicCalculations,
            dateCalculations: this.dateCalculations,
            daysUntilCalculations: this.daysUntilCalculations,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment || "";

        this.basicCalculations = event.data.basicCalculations || [];
        this.dateCalculations = event.data.dateCalculations || [];
        this.daysUntilCalculations = event.data.daysUntilCalculations || [];

        this.settingsSummary = this.calcSettingsSummary()
    }


    getBasicCalculations(): BasicCalculation[] {
        return this.basicCalculations;
    }

    setBasicCalculations(calculations: BasicCalculation[]): void {
        this.basicCalculations = calculations;
    }

    getDateCalculations(): DateCalculation[] {
        return this.dateCalculations;
    }

    setDateCalculations(calculations: DateCalculation[]): void {
        this.dateCalculations = calculations;
    }

    getDaysUntilCalculations(): DaysUntilCalculation[] {
        return this.daysUntilCalculations;
    }

    setDaysUntilCalculations(calculations: DaysUntilCalculation[]): void {
        this.daysUntilCalculations = calculations;
    }

    calcSettingsSummary() {
        const allCalculations = [
            ...this.basicCalculations,
            ...this.dateCalculations,
            ...this.daysUntilCalculations,
        ];
        return allCalculations
            .map((c) => {
                if ("operator" in c && "unit" in c) {
                    // Datumsberechnung
                    return `${c.column} ${c.operator} ${c.value} ${c.unit} (${c.dateFormat}) -> ${c.outputColumn}`;
                } else if ("targetDate" in c) {
                    // Tage bis Datenstand
                    return `${c.column} until ${c.targetDate} -> ${c.outputColumn}`;
                } else if ("operation" in c) {
                    // Grundrechenarten
                    return `${c.column} ${c.operation} ${c.value} -> ${c.outputColumn}`;
                }
                return "";
            })
            .join(", ") || "No calculations"
    }

}
