import React from "react";
import {AppendNodeModel} from "../AppendNodeModel";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    FormControl,
    Grid,
    Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
    Typography
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {propagateColsToChildren} from "../../../../../pages/home/Demo";


interface AppendNodeSettingsProps {
    open: boolean
    handleClose: () => void
    nodeModel: AppendNodeModel
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>
}

const getColor = (colName: string) => {
    if (colName.startsWith('m_')) return 'orange';
    if (colName.startsWith('T_')) return 'green';
    if (colName.startsWith('B_')) return 'blue';
    return 'black';
};

const AppendNodeSettings: React.FC<AppendNodeSettingsProps> = (props: AppendNodeSettingsProps) => {

    const dialogContentRef = React.useRef<HTMLDivElement>(null);

    const onApply = () => {

        props.setSettingsSummary("Append done")

        props.nodeModel.setComment(props.comment)

        const resColNames = props.nodeModel.calculateOutputCols_full()
        props.nodeModel.setOutputColumns(resColNames.withoutPrefix)

        propagateColsToChildren(props.nodeModel)

        onClose()
    }

    const onClose = () => {
        props.handleClose && props.handleClose()
    }

    // dont propagate events to componente below
    React.useEffect(() => {
        if (!props.open) return
        const dialogContent = dialogContentRef.current;
        const preventScroll = (e: WheelEvent) => {
            // e.preventDefault();
            e.stopPropagation();
        };

        const attachEventListener = () => {
            if (dialogContent) {
                dialogContent.addEventListener('wheel', preventScroll);
            }
        };

        if (props.open) {
            // Use setTimeout to ensure the dialog content is rendered before attaching the event listener
            setTimeout(attachEventListener, 0);
        }

        return () => {
            if (dialogContent) {
                dialogContent.removeEventListener('wheel', preventScroll);
            }
        };
    }, [props.open, dialogContentRef]);


    return (
        <>
            <Dialog open={props.open} onClose={onClose}
                    maxWidth="lg"
                    scroll="paper"
                    PaperProps={{sx: {backgroundColor: "white"}}}
                    disableEnforceFocus
                    fullWidth>
                <DialogHeader
                    headline={"Append "}
                    closeDialog={onClose}
                />
                <DialogContent ref={dialogContentRef}>
                    <Grid container alignItems="center">

                        <Grid item xs={4} container alignItems="center">
                            {/* Left-hand table column */}
                            <Grid item >
                                {(() => {
                                    const resColNames = props.nodeModel.calculateOutputCols_full();
                                    // resColNames.withPrefix is an array of strings such as "T_a", "m_b", "B_c", etc.
                                    // For each union column, determine what should appear in the left and right cells.
                                    const rows = resColNames.withPrefix.map((col) => {
                                        if (col.startsWith("T_")) {
                                            // Column exists only in left
                                            return {left: col.substring(2), right: "", color: getColor(col)};
                                        } else if (col.startsWith("B_")) {
                                            // Column exists only in right
                                            return {left: "", right: col.substring(2), color: getColor(col)};
                                        } else if (col.startsWith("m_")) {
                                            // Column exists in both – show in both columns
                                            return {left: col.substring(2), right: col.substring(2), color: getColor(col)};
                                        } else {
                                            // Fallback case (should not occur if prefixes are enforced)
                                            return {left: col.substring(2), right: "", color: getColor(col)};
                                        }
                                    });

                                    return (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>File Top</TableCell>
                                                    <TableCell>File Bottom</TableCell>
                                                </TableRow>    
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell style={{color: row.color}}>
                                                            {row.left}
                                                        </TableCell>
                                                        <TableCell style={{color: row.color}}>
                                                            {row.right}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    );
                                })()}
                            </Grid>

                            
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2} justifyContent={"center"}>

                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {"Resulting Column Names:"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {((): any => {
                                        const resColNames = props.nodeModel.calculateOutputCols_full()
                                        return resColNames.withPrefix.map((colNamePrx, index) => (
                                            <span key={index} style={{color: getColor(colNamePrx)}}>
                                                {resColNames.withoutPrefix[index]}, &nbsp;
                                            </span>
                                        ));
                                    })()}

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Node Comment"
                                value={props.comment}
                                onChange={(e) => props.setComment(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onApply}>Apply</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default AppendNodeSettings